import React, { useState } from "react";
import Select2 from "../Common/Select2Wrapper";
const JobCardpage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [statusFilter, setStatusFilter] = useState(""); // Default status

  const jobData = [
    {
      companylogo:
        "https://ajivainfotech.com/static/media/ajivalogo_45.4d887b2f187b6e34ec33.png",
      jobTitle: "Senior Frontend Developer - Next.js",
      company: "Ajiva Infotech",
      location: "New York City, United States of America",
      views: 150,
      applicants: 25,
      shortlisted: 10,
      status: "Open",
      postedDate: "2024-08-30",
    },
    {
      companylogo:
        "https://ajivainfotech.com/static/media/ajivalogo_45.4d887b2f187b6e34ec33.png",
      jobTitle: "Backend Developer - Node.js, Express, MongoDB",
      company: "Tech Solutions",
      location: "San Francisco Bay Area, United States of America",
      views: 200,
      applicants: 30,
      shortlisted: 12,
      status: "Open",
      postedDate: "2024-08-28",
    },
    {
      companylogo:
        "https://ajivainfotech.com/static/media/ajivalogo_45.4d887b2f187b6e34ec33.png",
      jobTitle: "Senior UI/UX Designer - Web and Mobile Application Design",
      company: "Creative Agency",
      location: "Los Angeles Metropolitan Area, United States of America",
      views: 120,
      applicants: 20,
      shortlisted: 8,
      status: "Closed",
      postedDate: "2024-08-25",
    },
    {
      companylogo:
        "https://ajivainfotech.com/static/media/ajivalogo_45.4d887b2f187b6e34ec33.png",
      jobTitle: "Project Manager - Agile, Scrum, and Team Leadership",
      company: "Enterprise Corp",
      location: "Chicago Downtown,  United States of America",
      views: 180,
      applicants: 40,
      shortlisted: 15,
      status: "Open",
      postedDate: "2024-08-29",
    },
  ];

  const statusOptions = [
    { value: "Publish", label: "Published", color: "#0085FF" },
    { value: "Hold", label: "Hold", color: "#003366" },
    { value: "Disabled", label: "Disabled", color: "#FFE513" },
    { value: "Closed", label: "Closed", color: "#FF0000" },
    { value: "Draft", label: "Draft", color: "#FFA500" },
    { value: "Block", label: "Block", color: "#000000" },
  ];
  const handlegetJobBystatus = (e) => {
    console.log("Selected Status:", e);
  };

  return (
    <>
      <div className="hrp-main-section-card">
        {jobData.map((job, index) => (
          <div className="hrp-manage-inner-card-section my-3">
            <div key={index} className="hrp-manage-job-list">
              <div>
                <div className="hrp-manage-main-section">
                  <div className="hrp-manage-title-header">
                    <div className="hrp-manage-logo-image">
                      <img className="" src={job.companylogo} />
                    </div>

                    <div className="flex gap-3">
                      {" "}
                      <div className="hrp-manage-inner-card-section-label relative">
                        <div className="flex items-center gap-2">
                          <div className="flex items-center gap-1">
                            <div className="hrp-manage-inner-icon-image items-center">
                              {/* Views Icon */}
                              <svg
                                className="icon"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                <circle cx="12" cy="12" r="3" />
                              </svg>
                            </div>
                            {/* <span className="hrp-manage-job-highlight">Views:</span> */}
                          </div>
                          <div className="hrp-manage-job-value flex gap-1 justify-center">
                            <div>{job.views}</div>
                          </div>
                        </div>

                        {/* Hover Label */}
                        <div className="hrp-card-detail-hover-label text-xs px-2 py-1 rounded-md">
                          <div> Tolate View</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Action Buttons */}
                  <div className="hrp-manage-actions-section">
                    <div
                      className="hrp-manage-action-btn"
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      <svg
                        className="icon"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5a2.1 2.1 0 1 1 3 3L7 19l-4 1 1-4Z" />
                      </svg>
                    </div>

                    {/* Dropdown Menu */}
                    {showMenu && (
                      <div className="hrp-manage-action-menu">
                        <div>
                          <svg
                            className="icon"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                            <circle cx="12" cy="12" r="3" />
                          </svg>{" "}
                          View
                        </div>
                        <div>
                          <svg
                            className="icon"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M12 20h9" />
                            <path d="M16.5 3.5a2.1 2.1 0 1 1 3 3L7 19l-4 1 1-4Z" />
                          </svg>{" "}
                          Edit
                        </div>
                        <div>
                          <svg
                            className="icon"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="18" cy="5" r="3" />
                            <circle cx="6" cy="12" r="3" />
                            <circle cx="18" cy="19" r="3" />
                            <path d="M8.59 13.51 15.42 17.5" />
                            <path d="m15.41 6.5-6.82 3.99" />
                          </svg>{" "}
                          Share
                        </div>
                        <div className="hrp-manage-delete-btn">
                          <svg
                            className="icon"
                            width="16"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="red"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M3 6h18" />
                            <path d="M8 6V4h8v2" />
                            <path d="M10 11v6" />
                            <path d="M14 11v6" />
                            <path d="M5 6h14v14H5z" />
                          </svg>{" "}
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="hrp-manage-job-details">
                  <div className="hrp-manage-job-title">
                    <h2 className="">{job.jobTitle}</h2>
                    <div className="hrp-manage-job-company">{job.company}</div>
                    <div className="hrp-manage-job-location  flex  gap-1">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="red"
                          width="16"
                          height="16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 2C8.134 2 5 5.134 5 9c0 4.792 5.69 10.912 6.107 11.393a1 1 0 0 0 1.786 0C13.31 19.912 19 13.792 19 9c0-3.866-3.134-7-7-7Zm0 2a5 5 0 0 0-5 5c0 3.336 3.37 7.816 5 9.805 1.63-1.99 5-6.47 5-9.805a5 5 0 0 0-5-5Zm0 3a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="" style={{ color: "#ffa500" }}>
                        {" "}
                        {job.location}
                      </div>
                    </div>
                  </div>
                  <div className="hrp-manage-job-row justify-between flex gap-2">
                    <div className=" flex flex-wrap gap-1">
                      {/* Applicants */}
                      <div className="hrp-manage-inner-card-section-label relative">
                        <div className="flex items-center gap-1">
                          <div className="flex items-center gap-1">
                            <div className="hrp-manage-inner-icon-image items-center">
                              {/* Applicant Icon */}
                              <svg
                                className="m-auto"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.3999 19.2C5.86107 18.6835 8.02095 16.3067 8.65391 16.3067H15.3463C16.2635 16.3067 18.1359 18.2769 18.5999 18.9714M21.5999 12C21.5999 17.302 17.3018 21.6 11.9999 21.6C6.69797 21.6 2.3999 17.302 2.3999 12C2.3999 6.69809 6.69797 2.40002 11.9999 2.40002C17.3018 2.40002 21.5999 6.69809 21.5999 12ZM15.4387 8.72798C15.4387 6.89647 13.8926 5.40002 12.0002 5.40002C10.1078 5.40002 8.56164 6.89647 8.56164 8.72798C8.56164 10.5595 10.1078 12.0559 12.0002 12.0559C13.8926 12.0559 15.4387 10.5595 15.4387 8.72798Z"
                                  stroke="#003366"
                                  stroke-width="2"
                                />
                              </svg>
                            </div>
                            <span className=" hrp-manage-job-highlight-applicant">
                              Applicants:
                            </span>
                          </div>
                          <div className=" hrp-manage-job-value flex gap-1 justify-center">
                            <div>{job.applicants}</div>
                          </div>
                        </div>
                        {/* Hover Label */}
                        <div className="hrp-card-detail-hover-label   text-xs px-2 py-1 rounded-md">
                          <div> Tolate Applicants</div>
                        </div>
                      </div>
                      {/* Shortlisted */}
                      <div className="hrp-manage-inner-card-section-label relative">
                        <div className="flex items-center gap-2">
                          <div className="flex items-center gap-1">
                            <div className="hrp-manage-inner-icon-image items-center">
                              {/* Shortlisted Icon */}
                              <svg
                                className="m-auto"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.3999 21.6L2.40031 17.9996C2.40053 16.0116 4.01224 14.4 6.00031 14.4H13.1998M16.1999 17.4L17.3999 18.6L21.5999 14.4M17.3999 2.40002C18.8561 3.2163 19.7999 4.5249 19.7999 6.00002C19.7999 7.47515 18.8561 8.78375 17.3999 9.60002M14.3999 6.00002C14.3999 7.98825 12.7881 9.60002 10.7999 9.60002C8.81167 9.60002 7.1999 7.98825 7.1999 6.00002C7.1999 4.0118 8.81167 2.40002 10.7999 2.40002C12.7881 2.40002 14.3999 4.0118 14.3999 6.00002Z"
                                  stroke="#003366"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <span className=" hrp-manage-job-highlight-applicant">
                              Shortlisted:
                            </span>
                          </div>
                          <div className="hrp-manage-job-value flex gap-1 justify-center">
                            <div>{job.shortlisted}</div>
                          </div>
                        </div>
                        {/* Hover Label */}
                        <div className="hrp-card-detail-hover-label text-xs px-2 py-1 rounded-md">
                          <div> Tolate Shortlisted</div>
                        </div>
                      </div>
                    </div>
                    {/* Job Status */}
                    <div className="flex items-center  gap-2">
                      {/* <span className="hrp-manage-job-highlight">Status:</span> */}
                      <div className="hrp-filtter-input-style w-full">
                        <div
                          className="relative job-search-label-section singleselect2 statusenroll hrp-table"
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          <label
                            className="Select2-lable"
                            onSelect={handlegetJobBystatus}
                          >
                            Status
                          </label>
                          <Select2
                            data={statusOptions.map((type) => ({
                              id: type.value,
                              text: type.label,
                              title:
                                type.value === "Block"
                                  ? "Contact with superadmin"
                                  : "",
                              color: type.color,
                            }))}
                            className="jobsearchsector p-1 w-full no-border appearance-none text-base/8"
                            value={statusFilter}
                            onSelect={handlegetJobBystatus}
                            options={{
                              placeholder: "Select Status",
                              width: "100%",
                              theme: "classic",
                            }}
                            disabled={job.status === "Block"}
                            optionRenderer={(option) => (
                              <div
                                title={option.title || ""}
                                style={{
                                  color: option.color,
                                  fontWeight: "bold",
                                }}
                              >
                                {option.text}
                              </div>
                            )}
                          />
                          {/* Status Dot */}
                          <span
                            className="status-dot"
                            style={{
                              position: "absolute",
                              left: "5px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              borderRadius: "50%",
                              width: "8px",
                              height: "8px",
                              backgroundColor:
                                statusOptions.find(
                                  (s) => s.value === job.status
                                )?.color || "#000",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="hrp-manage-job-value flex gap-1  items-center justify-end px-3 pt-1">
                    <div className="hrp-manage-inner-icon-image items-center">
                      <svg
                        className="m-auto"
                        width="10"
                        height="10"
                        viewBox="0 0 448 512"
                        fill="#003366"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256zm176 40c-13.3 0-24 10.7-24 24l0 48-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l48 0 0 48c0 13.3 10.7 24 24 24s24-10.7 24-24l0-48 48 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0 0-48c0-13.3-10.7-24-24-24z" />
                      </svg>
                    </div>
                    {/* <span className="hrp-manage-job-highlight">Posted On:</span> */}
                    <div className="hrp-manage-job-highlight-value">
                      {job.postedDate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default JobCardpage;
