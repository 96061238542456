import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Select2 from "../Common/Select2Wrapper";
import DefaultImage from "../../img/PlaceholderImage.jpg";
import Adduser from "../popup/AddUserEmployer";
import EditUser from "../popup/EditUserEmployer";
import { getUserData } from "../../helpers/utils";
import { usePopup } from "../Common/PopupContext";
import { useHistory } from "react-router-dom";
import CustomToastContainer from "../Common/CustomToastContainer";
import api from "../../Api";
import Cookies from "js-cookie";
import { encryptData } from "../../helpers/encryption";
import FeatherIcon from "feather-icons-react";
const domainpath = process.env.REACT_APP_DOMAIN;
const domainPath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const Manageuser = () => {
  const { openPopup } = usePopup();
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(0);
  const [data, setdata] = useState([]);
  const toastRef = useRef();
  const handleLoginClick = () => {
    const popup = <Adduser onClose={() => handleClosePopup("Popup closed")} />;
    openPopup(popup);
  };

  const handleDelete = async (jobId) => {
    const response = await api.deleteStaff(`${jobId}`);

    if (response.status != 500) {
      console.log("response", response);
      toastRef.current.addToast(response.message, "success");
      setRender(render + 1);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toastRef.current.addToast(
        "There was an error deleting the Staff!",
        "error"
      );
    }
  };
  const handleClosePopup = (message) => {
    openPopup();
  };
  const handleEditUser = (id) => {
    const popup = (
      <EditUser id={id} onClose={() => handleClosePopup("Popup closed")} />
    );
    openPopup(popup);
  };
  const handleSecertLogin = async (id) => {
    setLoading(true);
    try {
      const userData = {
        userId: id,
      };
      const response = await api.SeceretLoginInEmployer(userData);

      if (response.status !== 400) {
        toastRef.current.addToast("Login Successfully", "success");
        const { accessToken, refreshToken } = response?.user;
        const encryptedUserData = encryptData(response.user);
        const existingAccessToken =
          localStorage.getItem("accessToken") || Cookies.get("accessToken");
        const existingRefreshToken =
          localStorage.getItem("refreshToken") || Cookies.get("refreshToken");
        const existingUser =
          localStorage.getItem("user") || Cookies.get("user");
        if (existingAccessToken) {
          localStorage.setItem("adminaccessToken", existingAccessToken);
          Cookies.set("adminaccessToken", existingAccessToken);
        }
        if (existingRefreshToken) {
          localStorage.setItem("adminrefreshToken", existingRefreshToken);
          Cookies.set("adminrefreshToken", existingRefreshToken);
        }
        if (existingUser) {
          localStorage.setItem("adminuser", existingUser);
          Cookies.set("adminuser", existingUser);
        }
        // console.log("Existing tokens and user renamed as admin keys", {
        //   adminAccessToken: localStorage.getItem("adminaccessToken"),
        //   adminRefreshToken: localStorage.getItem("adminrefreshToken"),
        //   adminUser: localStorage.getItem("adminuser"),
        // });
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken);
        Cookies.set("user", encryptedUserData);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);

        setTimeout(() => {
          setLoading(false);
          window.location.href = "/dashboard";
        }, 2000);
      } else if (response.status === 400) {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );

        toastRef.current.addToast(response.data.msg, "error");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);

      toastRef.current.addToast(error.response?.data?.msg, "error");
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div
          className="flex justify-between items-center hover:underline"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {/* <FeatherIcon
            icon="eye"
            onClick={(e) => handleSecertLogin(row?._id)}
            className="cursor-pointer"
          /> */}
          <span
            style={{ marginLeft: "10px" }}
            className="cursor-pointer"
            // onClick={(e) => handleSecertLogin(row?._id)}
          >
            {row?.name}
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row?.position,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2 w-full item-center justify-center">
          <button
            className="manage-edit-btn text-white px-4 py-2"
            onClick={(e) => handleEditUser(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-edit-2 "
            >
              <g>
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </g>
            </svg>
          </button>
          <span
            className="manage-delete-btn text-white px-3 py-2 "
            onClick={(e) => handleDelete(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-trash-2 "
              color="red"
            >
              <g>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </g>
            </svg>
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    async function getusers() {
      try {
        const response = await api.getStaff(User?._id);
        if (response.status !== 400) {
          setdata(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getusers();
  }, [render]);

  const tableData = {
    columns,
    data,
  };

  const [visibleId, setVisibleId] = useState(null);
  const toggleVisibility = (id) => {
    setVisibleId(visibleId === id ? null : id);
  };

  return (
    <div className="px-2 md:px-3 py-8">
      <div className="bg-white md:p-4 py-4 rounded-lg shadow">
        <div className="flex md:p-0 px-4 justify-between space-y-3 ">
          <h2 className=" text-lg font-bold mb-4" style={{ color: "#003366" }}>
            Manage User
          </h2>
          <button
            className="manage-add-btn text-white px-3 py-1 Job-Post-btn"
            onClick={handleLoginClick}
          >
            Add User
          </button>
        </div>
        <div className="md:block hidden hrp-dasktop-secren-data-table">
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={data}
              defaultSortField="id"
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>

        <div className="block md:hidden hrp-mobile-secren-data-table hrp-data-table-manage-user">
          <div className=" hrp-main-section-card">
            {data.map((row, Index) => (
              <div className="hrp-manage-inner-card-section my-3">
                <div key={row._id || Index} className="hrp-manage-list">
                  <div className="p-2">
                    <div className="hrp-manage-main-section">
                      <div className="hrp-manage-actions-section">
                        <div
                          className="hrp-manage-action-btn"
                          onClick={() => toggleVisibility(row._id)}
                        >
                          <svg
                            className="icon"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M12 20h9" />
                            <path d="M16.5 3.5a2.1 2.1 0 1 1 3 3L7 19l-4 1 1-4Z" />
                          </svg>
                        </div>

                        {visibleId === row._id && (
                          <div className="hrp-manage-action-menu">
                            <div onClick={(e) => handleEditUser(row._id)}>
                              <svg
                                className="icon"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M12 20h9" />
                                <path d="M16.5 3.5a2.1 2.1 0 1 1 3 3L7 19l-4 1 1-4Z" />
                              </svg>
                              Edit
                            </div>

                            <div
                              className="hrp-manage-delete-btn"
                              onClick={(e) => handleDelete(row._id)}
                            >
                              <svg
                                className="icon"
                                width="16"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="red"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M3 6h18" />
                                <path d="M8 6V4h8v2" />
                                <path d="M10 11v6" />
                                <path d="M14 11v6" />
                                <path d="M5 6h14v14H5z" />
                              </svg>
                              Delete
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="hrp-manage-job-details">
                      <div className="hrp-manage-job-title">
                        <h2 className="">{row.name}</h2>
                        <div className="hrp-manage-job-company">
                          {row?.email}
                        </div>
                        <div className="hrp-manage-job-location  flex  gap-1">
                          <div>
                            <svg
                              width="16"
                              height="16"
                              fill="#003366"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                            </svg>
                          </div>
                          <div className="" style={{ color: "#ffa500" }}>
                            {" "}
                            {row?.phone || "Phone not available"}
                          </div>
                        </div>
                      </div>
                      <div className="hrp-manage-job-row justify-between flex gap-2">
                        <div className=" flex flex-wrap gap-1">
                          <div className="hrp-manage-inner-card-section-label relative">
                            <div className="flex items-center gap-1">
                              <div className="flex items-center gap-1">
                                <div className="hrp-manage-inner-icon-image items-center">
                                  <svg
                                    className="m-auto"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.3999 19.2C5.86107 18.6835 8.02095 16.3067 8.65391 16.3067H15.3463C16.2635 16.3067 18.1359 18.2769 18.5999 18.9714M21.5999 12C21.5999 17.302 17.3018 21.6 11.9999 21.6C6.69797 21.6 2.3999 17.302 2.3999 12C2.3999 6.69809 6.69797 2.40002 11.9999 2.40002C17.3018 2.40002 21.5999 6.69809 21.5999 12ZM15.4387 8.72798C15.4387 6.89647 13.8926 5.40002 12.0002 5.40002C10.1078 5.40002 8.56164 6.89647 8.56164 8.72798C8.56164 10.5595 10.1078 12.0559 12.0002 12.0559C13.8926 12.0559 15.4387 10.5595 15.4387 8.72798Z"
                                      stroke="#003366"
                                      strokeWidth="2"
                                    />
                                  </svg>
                                </div>
                                <span className=" hrp-manage-job-highlight-applicant">
                                  Roll:
                                </span>
                              </div>
                              <div className=" hrp-manage-job-value flex gap-1 justify-center">
                                <div>{row?.position}</div>
                              </div>
                            </div>

                            <div className="hrp-card-detail-hover-label   text-xs px-2 py-1 rounded-md">
                              <div> User Roll</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Manageuser;
